import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

interface DynamicRadioGroupProps {
    questionId: string;
    questionText: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    px: number;
    numOptions: number;
    lowerRatingDescription?: string;
    upperRatingDescription?: string;
    startIndex?: number; // New prop to determine the starting index
}

/*
Common component to display radio boxes where you can dynamically pass in values as shown below to create for example a survey.
Usage:
    const [values, setValues] = useState({
        Q1: '',
        Q2: '',
            ......
    });

        return .....
                <DynamicRadioGroup
                    questionId="Q1"
                    questionText="Q1. Explanation provided for the diagnosis, treatment & next steps"
                    value={values.Q1}
                    onChange={handleChange}
                    px={8}
                    numOptions={5}
                    lowerRatingDescription={'Extremely Dissatisfied'}
                    upperRatingDescription={'Extremely Satisfied'}
                    startIndex={1} // or 0
                />

See src/pages/ILHSurvey.tsx for comprehensive usage.
 */

const DynamicRadioGroup: React.FC<DynamicRadioGroupProps> = ({
    questionId,
    questionText,
    value,
    onChange,
    px,
    numOptions,
    lowerRatingDescription,
    upperRatingDescription,
    startIndex = 1, // Default to 1 if not provided
}) => {
    // Detect mobile view using useMediaQuery hook
    const mobileView = useMediaQuery('(max-width:600px)');

    return (
        <FormControl>
            <Box sx={{ px: px }}>
                <FormLabel id={questionId} sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {questionText}
                </FormLabel>
                <RadioGroup
                    row={!mobileView} // true for desktop, false for mobile (column layout)
                    aria-labelledby={`${questionId}-form-control-label-placement`}
                    name={questionId}
                    value={value}
                    onChange={onChange}
                >
                    <Box sx={{ typography: 'body1', fontWeight: 'normal', alignSelf: 'center', mb: mobileView ? 2 : 0 }}>
                        {lowerRatingDescription}
                    </Box>
                    {Array.from({ length: numOptions }, (_, index) => (
                        <FormControlLabel
                            key={`${questionId}-${index + startIndex}`}
                            value={`${index + startIndex}`}
                            control={<Radio />}
                            label={`${index + startIndex}`}
                            labelPlacement={mobileView ? 'start' : 'top'} // 'top' for mobile, 'bottom' for desktop
                            sx={{ alignSelf: 'center' }} // Adjust margin as needed
                        />
                    ))}
                    <Box sx={{ typography: 'body1', fontWeight: 'normal', alignSelf: 'center', mb: mobileView ? 2 : 0 }}>
                        {upperRatingDescription}
                    </Box>
                </RadioGroup>
            </Box>
        </FormControl>
    );
};

export default DynamicRadioGroup;