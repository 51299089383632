import React from 'react';
// import { useSelector } from 'react-redux';

export default function SurveySuccess({ history }) {
	// const signUrl = useSelector(store => store?.confirmInfo?.signUrl);
	return (
		<div className="container p-4 bg-white my-5 border text-dark" id="message">
			<h3>Thank you for completing this survey!</h3>
			{/* <img src={signUrl} alt="sign-img" /> */}
			<div>
				<h4>You can now close this browser window.</h4>
			</div>

		</div>
	);
}
