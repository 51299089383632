import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
Common component to display a "Yes/No/N/A" radio button group for survey questions.
Usage:
    <DynamicYesNoGroup
        questionId="Q1"
        questionText="Is this service satisfactory?"
        value={values.Q1}
        onChange={handleChange}
        px={8}
        numOptions={3} // up to 3 options allowed (e.g., Yes, No, N/A)
    />
*/

export default function DynamicYesNoGroup({ questionId, questionText, value, onChange, px, numOptions }) {
    // Detect mobile view using useMediaQuery hook
    const mobileView = useMediaQuery('(max-width:600px)');

    // Ensure numOptions is within the allowed range (1 to 3)
    if (numOptions < 1 || numOptions > 3) {
        console.error('DynamicYesNoGroup: numOptions must be between 1 and 3');
        return null;
    }

    // Define labels for the radio buttons
    const labels = ['Yes', 'No', 'N/A'].slice(0, numOptions);

    return (
        <FormControl>
            <Box sx={{ px: px }}>
                <FormLabel id={questionId} sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {questionText}
                </FormLabel>
                <RadioGroup
                    row={!mobileView} // true for desktop, false for mobile (column layout)
                    aria-labelledby={`${questionId}-form-control-label-placement`}
                    name={questionId}
                    value={value}
                    onChange={onChange}
                >
                    {labels.map((label, index) => (
                        <FormControlLabel
                            key={`${questionId}-${label}`}
                            value={label} // Value matches the label for easy identification
                            control={<Radio />}
                            label={labels[index]}
                            labelPlacement={mobileView ? 'start' : 'top'} // 'start' for mobile, 'top' for desktop
                            sx={{ alignSelf: 'center' }} // Adjust margin if needed
                        />
                    ))}
                </RadioGroup>
            </Box>
        </FormControl>
    );
}
