import React, { useMemo, useState } from "react";
import { useGetMonthlyAppointmentsQuery } from "../generated/graphql";
import { useTable, useSortBy } from "react-table";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Header from "../components/UI/Header/Header";
import { useHistory } from "react-router";

const GetMonthlyAppointments = () => {
  const history = useHistory();
  const [month, setMonth] = useState("07");
  const [year, setYear] = useState("2024");

  type FilterOption = {
    label: string;
    value: string;
  };

  const filtermonth: FilterOption[] = [];
  const filteryear: FilterOption[] = [];

  for (let i = 1; i <= 12; i++) {
    let v = `${i}`;
    if (v.length !== 2) {
      v = "0" + v;
    }
    filtermonth.push({ label: v, value: v });
  }

  for (
    let i = new Date().getFullYear();
    i >= new Date().getFullYear() - 3;
    i--
  ) {
    filteryear.push({ label: `${i}`, value: `${i}` });
  }
  const setYearMonth = (date) => {
    // format ("2014-02-04")
    setYear(date.substring(0, 4));
    setMonth(date.substring(5, 7));
  };
  console.log(setYearMonth);

  // console.log("year", year, " ", "month", month, " ", "year-month", year + "-" + month);


  const { data, loading, error } = useGetMonthlyAppointmentsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      filterbymonth: year + "-" + month, //"2024-07",
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "MRN",
        accessor: "mrn",
      },
      {
        Header: "Patient ID",
        accessor: "patientid",
      },
      {
        Header: "DOB",
        accessor: "dob",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "Clinic Name",
        accessor: "practicename",
      },
      {
        Header: "Appointment Date",
        accessor: "appointment",
      },
      {
        Header: "Appointment ID",
        accessor: "appid",
      },
      {
        Header: "Registration Date",
        accessor: "checkinon",
      },
      {
        Header: "Nurse Seen Time",
        accessor: "nurse_seen",
      },
      {
        Header: "Nurse Waiting Minutes",
        accessor: "admit_to_nurse_seen_waiting_minutes",
      },
      {
        Header: "Doctor Seen Time",
        accessor: "doctor_seen",
      },
      {
        Header: "Doctor Waiting Minutes",
        accessor: "admit_to_doctor_seen_waiting_minutes",
      },
      {
        Header: "Discharge Time",
        accessor: "discharged",
      },
      {
        Header: "Admit to Discharge Waiting Minutes",
        accessor: "admit_to_discharge_waiting_minutes",
      },
    ],
    []
  );

  const tableData = useMemo(() => data?.getMonthlyAppointments ?? [], [data]);

  // Function to convert table data to CSV format
  const convertToCSV = (data) => {
    const csvRows: string[] = []
    // Get headers

    const headers = columns.map((column) => column.Header);
    csvRows.push(headers.join(","));

    // Loop over the rows
    data.forEach((row) => {
      const values = headers.map((header) => {
        const column = columns.find((col) => col.Header === header);
        let value = column ? row[column.accessor] : "";
        return `"${value}"`; 
      });

      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  // Function to trigger CSV download
  const downloadCSV = () => {
    const csvData = convertToCSV(tableData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "download.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData }, useSortBy);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div className="container-fluid">
      <div>
        <TextField
          id="outlined-select-currency"
          select
          required={true}
          label="mm"
          size="small"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          variant="outlined"
          helperText="Month"
        >
          {filtermonth.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          required={true}
          label="yyyy"
          size="small"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          variant="outlined"
          helperText="Year"
        >
          {filteryear.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <Header>
        <>
          <div className="p-1"> </div>
          <button className="btn btn-primary btn-sm px-4" onClick={downloadCSV}>
            Export to CSV
          </button>
          <div className="p-1"> </div>
          <button
            className="btn btn-success btn-sm px-4"
            onClick={() => {
              history.replace({
                pathname: "/admit-patients",
              });
            }}
          >
            Admit Patients
          </button>
        </>
      </Header>
      <div className="d-flex justify-content-center ml-4 px-4 p-2">
        <h2>Monthly Appointments</h2>
      </div>
      <table
        {...getTableProps()}
        style={{
          backgroundColor: "#f0f0f0",
          border: "2px solid black",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    border: "1px solid grey",
                    padding: "10px",
                    backgroundColor: "#d3d3d3",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ border: "1px solid grey", padding: "8px" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default GetMonthlyAppointments;
