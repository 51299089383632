import React from "react";
import { Route } from "react-router";
import PatientRegistration from "./pages/PatientRegistration";
import PatientRegistrationConfirm from "./pages/PatientRegistrationConfirm";
import PatientregistrationSelfPay from "./pages/PatientRegistrationSelfPay";
import PatientRegistrationIL from "./pages/PatientRegistrationIL";
import Success from "./pages/Success";
import SignatureRecapture from "./pages/SignatureRecapture";
import SignatureRecaptureIL from "./pages/SignatureRecaptureIL";
import PatientReCaptureConfirm from "./pages/PatientReCaptureConfirm";
import NotFound404_patient from "./pages/404_patient";
import PrivateRoute from "./components/Routes/PrivateRoute";
import { SigPlus } from "./components/sigPlus/SigPlus";
import ILHSurvey from "./pages/ILHSurvey";
import SurveySuccess from "./pages/Success_Survey";


interface PatientRegistrationRoutesProps {}
export const PatientRegistrationRoutes: React.FC<PatientRegistrationRoutesProps> = () => {

// export const PatientRegistrationRoutes = () => {
  return (
    <>
      <Route path="/404" component={NotFound404_patient} />
      <Route path={["/ILHSurvey/:mmpid/:token"]} component={ILHSurvey} />
      <PrivateRoute
        exact
        path={[
          "/sig/QR/laya/ts/:token",
          "/sig/QR/1/ts/:token",
          "/sig/QR/il/ts/:token",
          "/sig/QR/2/ts/:token",
          "/sig/QR/fc/ts/:token",
          "/sig/QR/5/ts/:token",
        ]}
        component={SigPlus}
      />

      <Route
        exact
        path={["(/QR)?/(sp|3|4|ac)/:token"]}
        component={PatientregistrationSelfPay}
      />
      <Route
        exact
        path={["(/sig)?(/QR)?/(laya|fc|1|5)/:token"]}
        component={PatientRegistration}
      />
      <Route
        exact
        path={["(/sig)?(/QR)?/(il|2)/:token"]}
        component={PatientRegistrationIL}
      />
      <Route
        path={["/(ilconfirm|layaconfirm|spconfirm|fcconfirm|acconfirm)/:token"]}
        component={PatientRegistrationConfirm}
      />
      <Route
        path="(/recapture)/:pid?/:token?"
        component={SignatureRecapture}
      />
      <Route
        path="(/recaptureIL)/:pid?/:token?"
        component={SignatureRecaptureIL}
      />
      <Route
        path={["/recaptureconfirm/:pid?/:token?"]}
        component={PatientReCaptureConfirm}
      />
      <Route path="/patient-success" component={Success} />
      <Route path="/survey-success" component={SurveySuccess} />
    </>
  );
};
